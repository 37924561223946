














import AchievementsList from "@/components/Achievements/AchievementsList.vue";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue';

import { Component, Mixins } from 'vue-property-decorator';

@Component({
  data() {
    return {
      SitePermissionEnum
    }
  },
  components: {
    PermissionsBanner,
    PageTitle,
    AchievementsList
  }
})
export default class Achievements extends Mixins(TariffsTagsHelper) {
  mounted() {
    this.$baseTemplate.saveButton.hide()
  }
}
