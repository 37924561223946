























































































import { InputSetups } from "@/mixins/input-setups";
import { AchievementItem } from "@/includes/types/Achievements";

import AddEntityCard from 'piramis-base-components/src/components/AddEntityCard.vue';
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";

import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    ValidationObserver,
    TextInput,
    AddEntityCard,
  }
})
export default class AchievementsList extends Mixins(InputSetups) {
  @Prop({ type: Boolean, required: false, default: false }) disabled!: boolean

  achievement = { ...this.achievementModel() }

  isAddAchievementModalOpen = false

  get modalTitle() {
    return this.isNewAchievement ? this.$t('add_achievement') : this.$t('edit_achievement')
  }

  get modalOkText() {
    return this.isNewAchievement ? this.$t('add') : this.$t('save_button')
  }

  get achievements(): Array<AchievementItem> {
    return this.$store.state.chatState.chat?.common_config?.achievements ?? []
  }

  get isNewAchievement() {
    return this.achievement.id === 0
  }

  saveConfig() {
    this.$store.dispatch('saveChatConfigRequest', {
      config: this.$store.state.chatState.chat!.common_config
    })
  }

  resetAchievementModel() {
    this.achievement = { ...this.achievementModel() }
  }

  setToEdit(item: AchievementItem) {
    this.achievement = { ...item }

    this.isAddAchievementModalOpen = true
  }

  achievementModel(): AchievementItem {
    return {
      title: '',
      id: 0,
    }
  }

  handleOkClick() {
    if (this.isNewAchievement) {
      this.addAchievement()
    } else {
      this.updateAchievement()
    }
  }

  updateAchievement() {
    this.$store.dispatch('updateAchievement', this.achievement)
      .then(this.saveConfig)
      .then(() => {
        this.isAddAchievementModalOpen = false
      })
  }

  addAchievement() {
    this.$store.dispatch('addAchievement', this.achievement)
      .then(this.saveConfig)
      .then(() => {
        this.isAddAchievementModalOpen = false
      })
  }

  removeAchievement(id: AchievementItem['id']) {
    this.$store.dispatch('removeAchievement', id)
      .then(this.saveConfig)
      .then(() => {
        this.isAddAchievementModalOpen = false
      })
  }
}
